/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.resourceLedger {
  padding: 1rem;
}

li.ledgerEntry {
  list-style: none;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

table {
  table-layout: fixed;
  width: 100%
}

th {
  text-align: center;
}

td {
  vertical-align: top;
}

.input-amount {
  width: 100%;
}

.block {
  display: block;
}

.grain {
  background-color: wheat;
}

.meat {
  background-color: tomato;
}

.wood {
  background-color: burlywood;
}

.stone {
  background-color: slategray;
}

.fish {
  background-color: aquamarine;
}